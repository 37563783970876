import {navigate} from 'gatsby'

import normalizePath from '../utils/normalizePath'
import {isSSR} from '../constants'

interface RedirectProps {
  pageContext: {
    toPath: string
  }
}

export default function Redirect({pageContext: {toPath}}: RedirectProps): JSX.Element | null {
  if (!isSSR) {
    navigate(normalizePath(toPath), {replace: true})
  }
  return null
}
